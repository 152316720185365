import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { filter, map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http-service';
import { ApiUrlService } from 'src/app/shared/services/api-url.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-userimages',
  templateUrl: './userimages.component.html',
  styleUrls: ['./userimages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UserimagesComponent implements OnInit {

  items: GalleryItem[];
  deleteitemarray = []
  deleteitemnamearray = []
  imageData = [];
  routdata = this.activatedroute.snapshot.queryParams
  desabled = true
  showdtlbtn = false
  showSelectButton = true
  constructor(
    public gallery: Gallery,
    public lightbox: Lightbox,
    private activatedroute: ActivatedRoute,
    private http: HttpService,
    private apiurl: ApiUrlService,
    private cref: ChangeDetectorRef,
    private toaster: ToastrService,
    private modalService: NgbModal,

  ) {
    this.getuserimages()
  }

  ngOnInit() { }
  async getuserimages() {
    let result = await this.http.post(this.apiurl.url.getusreimagesbyfolder, { user_id: this.routdata.uId, folder_id: this.routdata.fId });
    this.imageData = result['data']
    this.showSelectButton = this.imageData.length ? true : false
    this.imageData.filter(e=>{
      e.selected = false
    })
    this.cref.detectChanges()
  }
  getdeleteitemvalue(event: any, data: any) {
    let selectAllInpupt:any = document.getElementsByClassName('selectAllInpupt')
    this.imageData.filter(e => {
      if (e == data) {
        e.selected = event.target.checked
      }
    })
    let b = this.imageData.some(item => (item.selected == false));
    if(b == false)selectAllInpupt[0].checked = true 
    else selectAllInpupt[0].checked = false
  }
  async deleteselecteditem() {
    this.deleteitemnamearray = []
    this.deleteitemarray = []
    this.imageData.filter(e => {
      if(e.selected == true){
        this.deleteitemnamearray.push(e.img_name)
        this.deleteitemarray.push(e.image_id)
      }
    })
    let result = await this.http.post(this.apiurl.url.deleteiamges, {
      img_name_arr: this.deleteitemnamearray,
      imgarr: this.deleteitemarray,
      user_id: this.routdata.uId,
      folder_id: this.routdata.fId
    });

    if (result['status'] == true) {
      this.toaster.success(result['message'])
    } else {
      this.toaster.error(result['message'])
    }
    this.deleteitemnamearray = []

    this.getuserimages()

  }
  deleteInvoice(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then((result) => {
        this.deleteselecteditem();

      }, (reason) => {

      });
  }

  onselectdelete() {
    this.deleteitemnamearray = []
    this.desabled = true
    if (this.showdtlbtn == true) { this.showdtlbtn = false }
    else {
      this.showdtlbtn = true
    }
  }
  showDlt = false
  onSelectAll(event) {
    this.showDlt = event.target.checked
    const CHECKED = event.target.checked;
    this.imageData.forEach(item => item.selected = CHECKED);
  }
}

