// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiUrl:'http://103.15.67.78:7001/',
    apiUrl:'https://sketchhausadmin.com/',
  Token : 'Barrier_eyJhbGciOiJIUzI1fNiggIsIfnR5cCI6IkpXgVCJ9.eyJlbWFpbCI6IfnN0cmluZ0BnbWFpbC5jb20iLCJpYXQiOjE2Mzg5NDA5MTYsImV4cCI6MTYzODk0MDk3Nn0.8SVQVKMTZ01W5QVhBGStgjNa9QUXdbR0lSAyv8jgUV8'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
